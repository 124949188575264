body {
  background-color: #f5f5f5;
}

.access-exit-btn{
  width: 60px;
  height: 60px;
  position: fixed;
  bottom: 40px;
  right: 0px;
  z-index: 99;
}

/*footer*/
.footer {
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  margin-top: 20px;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
}

@media (max-width: 767px) {}