.related-products {
    margin-top: 40px;
}

.related-product-card {
  width: 300px;
  height: 430px;
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
  background-color: #f5f5f5;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.related-product-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
  align-items: center;
  height: 100%;
  overflow: hidden;
}

.related-product-image {
  max-width: 100%;
  max-height: 150px;
  object-fit: contain;
  margin-bottom: 10px;
}

.related-product-title {
  font-size: 1.5rem;
  margin: 0;
  color: #333;
  text-align: center;
  white-space: normal;
  word-wrap: break-word;
}

.related-product-description {
  font-size: 0.9rem;
  color: #666;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.related-product-price {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 0;
}

.related-product-bottom{
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
}

.related-product-button {
  background-color: #25d366;
  color: black;
  border: none;
  padding: 15px 30px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-decoration: none;
  border-radius: 45px;
}

.related-product-button:hover {
  background-color: #128c7e;
}

.related-products-header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
}


.related-products-list {
    display: flex;
    justify-content: space-between;
}

  .related-product > .related-product-bottom{
    display: flex;
    justify-content: center;
  }

  .related-product .related-product-description{
    display: flex;
    justify-content: center;
  }

  @media (max-width: 767px){
    .related-product-card{
      width: calc(42%);
      justify-content: space-between;
    }

    .related-product-title{
      font-size: 1.1rem;

    }

    .related-product-price {
      font-size: 1rem;
      margin-top: -20px;
      margin-bottom: 0px;
    }

    .related-product-button{
      display: flex;
      justify-content: center;
      text-align: center;
      align-items: center;
      margin-bottom: 0px;
    }

    .related-product-button-text{
      width: 65px;
      font-size: 0.8rem;
    }

  }