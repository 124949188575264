/* Popup Component */
/* popup.css */
.pop-up-component {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(214, 239, 241, 0.63);
    padding: 10px;
    flex-direction: row-reverse;
    height: 80px;
    direction: rtl;
  }
  
  .pop-up-title {
    text-align: left;
    margin-left: 20px;
    font-size: 20px;
  }

  .information-icon {
    margin-right: 10px;
    margin-left: 10px;
    border: 1px white solid;
    border-radius: 9999px;
    padding: 10px;
  }
  
  .pop-up-phone {
    text-align: right;
    padding-right: 20px;
    font-size: 20px;
  }
  
  .phone-icon {
    margin-left: 10px;
    margin-right: 10px;
  }
  
  @media (max-width: 767px) {
    .pop-up-component {
      display: none;
    }
  }