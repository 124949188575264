/*banner.css*/
.banner-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 200px;
}

.scrolled .banner-container {
    height: auto;
    padding: 10px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

.banner-logo {
    max-width: 100%;
    max-height: 100%;
    height: 200px;

}

.scrolled .banner-logo {
    height: auto;
    max-height: 60px !important;
}

.small-logo {
    max-height: 60px !important;
    margin-top: -40px;
}

@media (max-width: 767px) {
    .banner-container {
        height: 70px;
        display: flex;
        align-items: center;
    }
    
    .banner-logo {
        height: 60px;
    }

    .scrolled .banner-container {
        display: none;
    }
}