.product-view-component {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.product-details {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: #F5F5F5;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.product-view-image {
    height: 300px;
    margin-right: 200px;
}

.product-view-text {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.product-view-name {
    font-size: 1.8rem;
    margin: 0;
    color: #333;
}

.product-view-description {
    font-size: 0.9rem;
    color: #666;
    margin: 10px 0;
    width: 600px;
}

.product-view-price {
    font-size: 14px;
    font-weight: bold;
    align-self: flex-end; 
    margin: 0;
}

hr {
    border-color: #D9D9D9 !important;
    border-style: solid;
    border-width: 1px;
    height: 1px; 
    width: 90%;
    align-self: center;
}


.product-view-button {
    background-color: #25d366;
    color: black;
    border: none;
    padding: 15px 30px;
    font-size: 1.2rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-decoration: none;
    border-radius: 45px;
    margin-top: 20px;
}

.product-view-button:hover {
    background-color: #128c7e;
}

@media (max-width: 767px) {
    .product-view-component {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 100px;
    }

    .product-details {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
        width: 90vw;
    }

    .product-view-image {
        width: 150px;
        height: 150px;
        object-fit: contain;
        margin: 0;
    }

    .product-details{
        width: 90vw;
    }

    .related-products-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 20px;
    }

    .related-product {
        width: 45%;
        margin: 10px;
    }

    .product-view-name {
        text-align: center;
        width: 85vw;
    }

    .product-view-description{
        width: 70vw;
        text-align: center;
    }


    .related-product {
        width: 40%;
        margin-right: 10px;
        margin-top: 20px;
    }
}