/*navbar.css*/
.navbar-container {
    background-color: #F5F5F5;
    padding: 10px 0;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-bottom: grey 1px solid;
    transition: height 2s ease;
}

.scrolled .navbar-container {
    height: 50px;
}

.banner-logo {
    max-width: 100%;
    max-height: 100%;
    height: 200px;
    transition: transform 0.8s ease;
}

.scrolled .banner-logo {
    animation: moveLogoScrolled 0.8s ease forwards;
    height: 150px;
}

:not(.scrolled) .banner-logo {
    animation: moveLogoUnscrolled 0.8s ease forwards;
}

/* .mobile-home-button {
    height: 30px;
    margin-top: 5px;
} */

.mobile-home-button {
    height: 100px;
    transition: height 0.6s ease;
}

.mobile-home-button.scrolled {
    height: 30px;
}

.banner-logo {
    max-width: 100%;
    max-height: 100%;
    height: 200px;
    animation: moveAcrossScreen 10s linear infinite;
    /* Adjust duration and timing function */
    transition: transform 0.3s ease;
    /* Add the transition property for the scroll effect */
}

.house-button {
    margin-top: 20px;
}


.navbar-search {
    position: relative;
    margin-top: 20px;
    width: 850px; 
}

.custom-input {
    background-color: transparent;
    outline: none;
    width: 850px; 
    border: 1px solid;
    padding: 0.5em 3em 0.5em 0.5em;
    font-size: 1em;
    border-radius: 0.8em;
    text-align: right;
}

.fa-solid.fa-magnifying-glass {
    position: absolute; 
    top: 50%;
    right: 20px; 
    transform: translateY(-50%);
}

.custom-input::placeholder {
    color: #abb2bf;
    direction: rtl;
    text-align: right;
}

.search-result-container {
    position: relative;
    display: inline-block;
}

.search-result {
    height: auto;
    max-height: 300px;
    width: 700px;
    background-color: #f5f5f5;
    max-width: 300px;
    position: absolute;
    top: 100%; /* Change this to position it below the input */
    left: -150px; /* Change this to position it at the same left edge as the input */
    transform: translate(0, 0); /* Remove the previous transform */
    z-index: 999;
    padding: 10px;
    border: 1px solid #ccc;
    overflow-y: auto;
    direction: rtl;
}

.search-result ul {
    list-style: none;
}

.search-result-content{
    display: flex;
    flex-direction: row;
}

.result-image{
    height: 40px;
    object-fit: contain;
    margin-left: 20px;
}

.navbars {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-top: 20px;
    background-color: #f5f5f5;
}

.navbar-button{
    background-color: rgba(214, 239, 241, 0.63);
    width: 190px;
    height: 45px;
    border-radius: 12px;
    border: 0px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.navbar-brand-container {
    background-color: #f5f5f5;
    padding: 0px;
}

.navbar-brand {
    font-size: 1.5rem;
    background-color: wheat;
    border-radius: 30px;
    padding: 10px;
}

.scrolled .navbar-brand {
    padding: 5px 10px;
    border-radius: 5px;
}

.small-logo {
    max-height: 60px !important;
}

@media (max-width: 1200px) {
    .navbar-search {
        width: 500px; 
    }
    
    .custom-input {
        width: 500px; 
    }
    }


@media (max-width: 767px) {
    .navbar-container {
        position: fixed;
        flex-direction: column;
        justify-content: center;
        padding: 5px 0;
    }

    .navbars {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
    }

    .navbar-brand-container {
        margin-top: 5px;
        display: flex;
        justify-content: center;
    }

    .navbar-brand {
        height: 30px;
        font-size: medium;
        width: 300px;
        text-align: center;
    }

    .navbar-search {
        width: 300px; 
    }
    
    .custom-input {
        width: 300px; 
    }
    

    .scrolled .navbars {
        display: none;
    }

    .search-result{
        width: 300px;
    }
}

@keyframes moveLogoScrolled {
    from {
        transform: translateY(0);
    }

    to {
        transform: translateY(-50px);
    }
}

@keyframes moveLogoUnscrolled {
    from {
        transform: translateY(-50px);
    }

    to {
        transform: translateY(0);
    }
}