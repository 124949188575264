.products-component {
    text-align: center;
    padding: 20px;
}

.mobile-logo-container{
    display: flex;
   justify-content: center; 
}

.mobile-logo {
    display: none;
}

.hot-deals-heading {
    font-size: 60px;
    margin-bottom: 20px;
}

.filter-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.filter-options {
    display: flex;
    gap: 20px;
}

.filter-select {
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.products-list {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
}

.home-p-card {
    margin-right: 100px;
}

@media (max-width: 767px) {

    .mobile-logo-container{
        margin-bottom: 20px;

    }

    .mobile-logo {
        display: block;
        height: 110px;
        object-fit: contain;
        margin-bottom: 10px;
        margin-top: 20px;
    }
    
    .products-list {
        gap: 0px;
    }

    .product-card {
        width: 43%;
    }

    .product-title{
        font-size: 1rem;
    }

    .product-price{
        font-size: 0.9rem;
        margin-top: 0px;
        margin-bottom: 20px;
    }

    .product-button{
        font-size: 0.6rem;
        font-weight: bold;
    }

    .wa-icon{
        font-weight: bold;
    }

    .hot-deals-heading {
        font-size: 40px;
        font-weight: 400;
    }

    .filter-container {
        flex-direction: column;
        align-items: center;
    }

    .filter-options {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }

    .filter-select,
    .filter-input {
        width: 100%;
        max-width: 250px;
    }

    .products-component {
        margin-top: 160px;
    }
}